<template lang="pug">
.w-100
  navs

  .content-height
    .brands-bg
      .back-opacy
        h3.font-weight-bold.text-white.mb-0.text-shadow Contacto

    .section
      b-container
        h3.font-weight-bold.mb-3.text-center Sucursales
        p.text-secondary.text-center.mb-5 Visita cualquiera de nuestras sucursales

        b-row
          b-col(md='4', v-for='(branch, index) in branches', :key='index')
            b-card.mb-0(no-body)
              a(:href='branch.wa', target='_blank')
                b-img.wa-icon(src='@/assets/images/icons/wa.png', height='50')
              a.map-container.bg-light(:href='branch.map', target='_blank')
                iframe(:src='branch.url', width='100%', height='250px', frameborder='0', style='border: 0')
              .p-3.text-center
                p.font-weight-bold {{ branch.title }}
                p.text-secondary.mb-1.text-sm {{ branch.text }}
                p.text-secondary.mb-0.text-sm(v-html='branch.text1')

    contact-form

  footer-component
</template>

<script>
import ContactForm from '@/components/ContactForm.vue'
import FooterComponent from '@/components/Footer.vue'
import Navs from '@/components/Navs.vue'

export default {
  components: { ContactForm, FooterComponent, Navs },
  data() {
    return {
      branches: [
        {
          map: 'https://goo.gl/maps/ZE5hnwRygVfiKF6T8',
          url: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3764.946822568584!2d-99.0772109!3d19.3281137!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb5f5afe1c5665f5d!2sMatelco%20Materiales%20Electricos!5e0!3m2!1ses!2smx!4v1612578496547!5m2!1ses!2smx',
          title: 'Sucursal CDMX',
          text: 'Av. San Lorenzo 279 1C-Bis, Parque Industrial Iztapalapa, Col. Cerro de la Estrella 09860',
          text1: 'Tels. 55 56 70 38 77 <br/> 55 56 70 50 06 <br/><br/>',
          wa: 'https://wa.me/525530077682',
        },
        {
          map: 'https://goo.gl/maps/8odWsoC6ouQ6YxQf8',
          url: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1882.6154099728558!2d-98.2415718!3d19.3157878!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x911f84def10f8014!2sMatelco!5e0!3m2!1ses!2smx!4v1612578856252!5m2!1ses!2smx',
          title: 'Sucursal Tlaxcala',
          text: '20 de Noviembre No. 41 A-6, Col. Centro 90000, Tlaxcala, Tlax.',
          text1: 'Tels. 24 64 62 64 25 <br/> 24 64 62 15 22 <br/> 24 64 62 15 84',
          wa: 'https://wa.me/522461126560',
        },
        {
          map: 'https://goo.gl/maps/9E21w8xFkn7R98md7',
          url: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15067.192887352809!2d-98.2101222!3d19.2476237!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x77a82882cd6664e7!2sMatelco%20Materiales%20El%C3%A9ctricos!5e0!3m2!1ses!2smx!4v1612579061814!5m2!1ses!2smx',
          title: 'Sucursal Xiloxoxtla',
          text: 'Lote 3 Manzana 7 Nave C, Parque Industrial Xiloxoxtla, Xiloxoxtla, Tlaxcala 90180',
          text1: 'Tels. 26 64 61 81 12 <br/> 26 64 16 43 49 <br/> 26 64 16 42 86',
          wa: 'https://wa.me/522461126562',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include media-breakpoint-up(lg) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.back-opacy {
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.brands-bg {
  width: 100%;
  height: 130px;
  background-image: url('~@/assets/images/contact-bg.png');
  background-position: center;
  background-size: cover;
}

.content-height {
  margin-top: 106px;
  min-height: calc(100vh - 106px - 177px);

  @include media-breakpoint-up(md) {
    margin-top: 112px;
    min-height: calc(100vh - 112px - 57.31px);
  }
}

.map-container {
  position: relative;
  height: 250px;
}

.wa-icon {
  position: absolute;
  top: 60%;
  right: 0px;
  z-index: 1;
  cursor: pointer;
}
</style>